import React from 'react';
import FeaturedArticles from './FeaturedArticles';

const Contact = () => {
    return (
        <main>
            <section className="presentation">
                <h1 className="intro-text">contactez-nous 📧</h1>
                <p className="intro-text">
                    Si vous avez des questions ou des suggestions, n'hésitez pas à nous contacter via le formulaire ci-dessous ou directement par email.
                </p>
            </section>
            <section className="contact">
                <h2>Formulaire de Contact</h2>
                <form action="contact-form-handler.php" method="post">
                    <label for="name">Nom:</label>
                    <input type="text" id="name" name="name" required />
                    <label for="email">Email:</label>
                    <input type="email" id="email" name="email" required />
                    <label for="message">Message:</label>
                    <textarea id="message" name="message" rows="4" required></textarea>
                    <button type="submit">Envoyer</button>
                </form>
                <p>Ou contactez-nous directement par email à : <a href="mailto:contact@karaouf.com">contact@karaouf.com</a></p>
            </section>
            <FeaturedArticles />
        </main>
    );
};

export default Contact;
