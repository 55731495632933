import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Ensure you have a separate CSS file for Header component styles

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header>
            <div className="navigation">
                <div className="logo-text">karaouf.</div>
                <nav className="nav-tabs">
                    <ul className={`nav-list ${isOpen ? 'show' : ''}`}>
                        <li><Link to="/" onClick={() => setIsOpen(false)}>accueil</Link></li>
                        <li><Link to="/clickdroit" onClick={() => setIsOpen(false)}>para-légal</Link></li>
                        <li><Link to="/nutrition-diabete" onClick={() => setIsOpen(false)}>nutrition</Link></li>
                        <li><Link to="/about" onClick={() => setIsOpen(false)}>à propos</Link></li>
                        <li><Link to="/contact" onClick={() => setIsOpen(false)}>contact</Link></li>
                    </ul>
                    <button className={`hamburger ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </nav>
            </div>
        </header>
    );
};

export default Header;
