import React from 'react';
import FeaturedArticles from './FeaturedArticles';

const Home = () => {
    return (
        <main>
            <section className="presentation">
                <h1 className="intro-text">Bienvenue 👋</h1>
                <p className="intro-text">
                    <strong>Découvrez des informations pratiques</strong> pour votre quotidien en matière de <em>droit civil</em> et de <em>nutrition</em>.
                </p>
                <p className="intro-text">
                    <em>Parajuriste</em> le jour, <em>diabétique</em> toujours, je vous apporte des conseils avec une dose d'expertise et de vécu !
                </p>
            </section>
            <FeaturedArticles />
        </main>
    );
};

export default Home;
