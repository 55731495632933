import React from 'react';
import FeaturedArticles from './FeaturedArticles';

const ClickDroit = () => {
    return (
        <main>
            <section className="presentation">
                <h1 className="intro-text">conseils para-légaux 📝</h1>
                <p className="intro-text">
                    <strong>Explorez les articles sur le droit civil</strong> et découvrez des conseils pratiques pour vos affaires juridiques quotidiennes.
                </p>
            </section>
            <FeaturedArticles />
        </main>
    );
};

export default ClickDroit;
