import React from 'react';

const FeaturedArticles = () => {
    return (
        <section className="featured-articles">
            <h2>&#128293; articles en vedette &#128293;</h2>
            <div className="articles-container">
                <div className="article" id="article1">
                    <iframe src="/article1.html" title="Article 1"></iframe>
                </div>
                <div className="article" id="article2">
                    <iframe src="/article2.html" title="Article 2"></iframe>
                </div>
            </div>
        </section>
    );
};

export default FeaturedArticles;
