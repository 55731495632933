import React from 'react';
import FeaturedArticles from './FeaturedArticles';

const About = () => {
    return (
        <main>
            <section className="about">
                <h2 className="underline_title">la mission de <strong>karaouf</strong>.</h2>
                <div className="feature-cards">
                    <div className="card-law">
                        <div className="card-content">
                            <h2 className="underline_gluc">📝 section juridique 📝</h2>
                            <p className="card-description">
                                <strong>Parajuriste en droit civil</strong> à l'aide juridique au Québec, j'analyse les cas juridiques quotidiens et j'offre aux lecteurs des moyens d'évaluer leur situation et de résoudre les conflits de manière raisonnable et économique.
                            </p>
                        </div>
                    </div>
                    <div className="card-nutrition">
                        <div className="card-content">
                            <h2>🍎 section glucose 🍎</h2>
                            <p className="card-description">
                                <strong>Diabétique de type 1</strong>, je partage les résultats de ma propre gestion du glucose. J'offre des outils pratiques et éprouvés pour une meilleure maîtrise de votre santé, apportant une expertise authentique et accessible.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <FeaturedArticles />
        </main>
    );
};

export default About;
