import React from 'react';
import FeaturedArticles from './FeaturedArticles';

const NutritionDiabete = () => {
    return (
        <main>
            <section className="presentation">
                <h1 className="intro-text">nutrition 🍎</h1>
                <p className="intro-text">
                    <strong>Découvrez des conseils sur la gestion du glucose</strong> et des informations pratiques sur la nutrition pour une vie saine.
                </p>
            </section>
            <FeaturedArticles />
        </main>
    );
};

export default NutritionDiabete;
