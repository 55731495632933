import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import ClickDroit from './components/ClickDroit';
import NutritionDiabete from './components/NutritionDiabete';
import About from './components/About';
import Contact from './components/Contact';
import './styles.css';
import './components/Header.css'; // Import Header CSS

const App = () => {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/clickdroit" element={<ClickDroit />} />
                <Route path="/nutrition-diabete" element={<NutritionDiabete />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;
